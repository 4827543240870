const handleMultiOptionFinanceAmountInputClosingBackgroundClick: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const target: any = event?.currentTarget || event?.target;
    const multiOptionFinanceAmountInput: any = target?.closest('.multiOptionFinanceAmountInput') || null;

    multiOptionFinanceAmountInput.classList.remove('active_currency');
    multiOptionFinanceAmountInput.classList.remove('active_amount');
  } catch (error) {
    console.error(error);
  }
};

const toggleMultiOptionFinanceAmountInputOptionCurrencyActive: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const target: any = event?.currentTarget || event?.target;
    const multiOptionFinanceAmountInput: any = target?.closest('.multiOptionFinanceAmountInput') || null;

    multiOptionFinanceAmountInput.classList.toggle('active_currency');
    multiOptionFinanceAmountInput.classList.remove('active_amount');
  } catch (error) {
    console.error(error);
  }
};

const toggleMultiOptionFinanceAmountInputOptionAmountActive: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const target: any = event?.currentTarget || event?.target;
    const multiOptionFinanceAmountInput: any = target?.closest('.multiOptionFinanceAmountInput') || null;

    multiOptionFinanceAmountInput.classList.toggle('active_amount');
    multiOptionFinanceAmountInput.classList.remove('active_currency');
    multiOptionFinanceAmountInput.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const handleMultiOptionFinanceAmountInputOptionCurrencySelect: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const option: any = event?.currentTarget || event?.target;
    const optionPrefix: string = option?.dataset?.prefix || '';
    const optionSource: string = option?.dataset?.source || '';
    const optionAlt: string = option?.dataset?.alt || '';
    const multiOptionFinanceAmountInput: any = option?.closest('.multiOptionFinanceAmountInput') || null;
    const currentFlag: any = multiOptionFinanceAmountInput?.getElementsByClassName('currentFlag')[0];
    const currentFlagImage: any = currentFlag?.getElementsByTagName('IMG')[0];
    const amountOptionContainer: any = multiOptionFinanceAmountInput?.getElementsByClassName('options_amount')[0];
    const amountPrefixes: any = amountOptionContainer?.getElementsByClassName('prefix');
    const currentPrefix: string = amountOptionContainer?.getElementsByClassName('prefix')[0]?.innerText || '';
    const finalInput: any = multiOptionFinanceAmountInput.getElementsByTagName('INPUT')[0];
    const currentAmount: any = multiOptionFinanceAmountInput?.getElementsByClassName('currentAmount')[0];
    const currentAmountTextInput: any = currentAmount?.getElementsByTagName('P')[0];
    var finalInputValue: string = finalInput?.value || '';

    finalInputValue = finalInputValue.replace(currentPrefix, optionPrefix);

    for (var a: number = 0; a < amountPrefixes.length; a++) {
      const amountPrefix: any = amountPrefixes[a];

      amountPrefix.innerText = optionPrefix;
    }

    currentFlagImage.src = optionSource;
    currentFlagImage.alt = optionAlt;
    finalInput.value = finalInputValue;

    if (finalInputValue !== '') {
      currentAmountTextInput.innerText = finalInputValue;
    }

    multiOptionFinanceAmountInput.classList.remove('active_currency');
  } catch (error) {
    console.error(error);
  }
};

const handleMultiOptionFinanceAmountInputOptionAmountSelect: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const option: any = event?.currentTarget || event?.target;
    const amount: string = option?.innerText || '';
    const multiOptionFinanceAmountInput: any = option?.closest('.multiOptionFinanceAmountInput') || null;
    const finalInput: any = multiOptionFinanceAmountInput.getElementsByTagName('INPUT')[0];
    const currentAmount: any = multiOptionFinanceAmountInput?.getElementsByClassName('currentAmount')[0];
    const currentAmountTextInput: any = currentAmount?.getElementsByTagName('P')[0];

    currentAmountTextInput.innerText = amount;
    finalInput.value = amount;
    multiOptionFinanceAmountInput.classList.remove('active_amount');
  } catch (error) {
    console.error(error);
  }
};

const setMultiOptionFinanceAmountInputError: (
  multiOptionFinanceAmountInputID: string
) => void = multiOptionFinanceAmountInputID => {
  try {
    const multiOptionFinanceAmountInput: any = document.getElementById(multiOptionFinanceAmountInputID);
    const parent: any = multiOptionFinanceAmountInput?.closest('.multiOptionFinanceAmountInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const relocateMultiOptionFinanceAmountInputValueByIdToNewInputByIdAndReturnSuccess: (
  MultiOptionFinanceAmountInputID: string,
  newInputID: string
) => boolean = (MultiOptionFinanceAmountInputID, newInputID) => {
  try {
    const MultiOptionFinanceAmountInput: any = document.getElementById(MultiOptionFinanceAmountInputID);
    const newInput: any = document.getElementById(newInputID);
    const financeAmountValue: string = MultiOptionFinanceAmountInput?.value || '';

    newInput.value = financeAmountValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
